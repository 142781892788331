<script>
import { mapActions, mapMutations, mapState } from "vuex";
import MenuComponent from "@/components/menu/MenuComponent";
import MainButtonIcon from "@/components/helpers/MainButtonIcon";
import MainNotice from "@/components/helpers/MainNotice";

export default {
  components: {
    MainButtonIcon,
    MenuComponent,
    MainNotice,
  },

  data() {
    return {
      isShownMenu: false,
      isShownNotice: false,
    };
  },

  computed: {
    ...mapState({
      notification: (state) => state.notification,
    }),

    isShownTitle() {
      return this.$route?.meta?.title;
    },
  },

  methods: {
    ...mapActions({
      createNotification: "createNotification",
    }),

    ...mapMutations(["SET_NOTIFICATION"]),

    hideMenu(classList) {
      if (classList.contains("main-layout__menu-wrapper")) {
        this.isShownMenu = false;
      }
    },

    clearNotification() {
      this.SET_NOTIFICATION(null);
    },
  },
};
</script>

<template>
  <div class="main-layout">
    <div
      class="main-layout__menu-wrapper"
      :class="{ 'main-layout__menu-wrapper_visible': isShownMenu }"
      @click="hideMenu($event.target.classList)"
    >
      <menu-component class="main-layout__menu" @close="isShownMenu = false" />
    </div>

    <div class="main-layout__header header">
      <img src="../../assets/logo.svg" alt="" />

      <div class="header__company-name">
        <p>МАРКЕТПЛЕЙС</p>
      </div>

      <main-button-icon class="header__button" @click="isShownMenu = true">
        <span class="header__icon icon-burger-menu"></span>
      </main-button-icon>
    </div>

    <div class="main-layout__page-wrapper">
      <h1 v-if="isShownTitle" class="main-layout__title">
        {{ $route.meta.title }}
      </h1>

      <router-view />
    </div>

    <main-notice
      v-if="notification"
      :notice-data="notification"
      :auto-close="true"
      @close="clearNotification"
    />
  </div>
</template>

<style lang="scss" scoped>
.main-layout {
  height: 100%;
  display: grid;
  grid-template-columns: 404px 1fr;

  &__page-wrapper {
    background-color: $light-second;
    padding: 72px 60px 48px;
    overflow: auto;
  }

  &__title {
    @include title-3;
    color: $dark-primary;
    margin-bottom: 48px;
  }

  &__header {
    display: none;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 0 48px;
    background: $light-primary;
  }

  .header {
    &__company-name {
      text-align: left;

      p {
        color: $dark-sixth;
        @include text-2();
        font-weight: 400;
      }
    }

    &__button {
      margin-left: auto;
    }

    &__icon {
      display: block;
      width: 32px;
      height: 32px;
      background-color: $dark-primary;
    }
  }
}

@media (max-width: 1280px) {
  .main-layout {
    grid-template-columns: 372px 1fr;

    &__page-wrapper {
      padding: 72px 48px 48px;
    }
  }
}

@media (max-width: 1024px) {
  .main-layout {
    grid-template-columns: 1fr;
    grid-template-rows: 56px 1fr;

    &__page-wrapper {
      min-width: 100%;
    }

    &__menu-wrapper {
      position: fixed;
      left: -120%;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 99999;
      background: rgba(71, 71, 71, 0.6);

      &_visible {
        left: 0;
      }
    }

    &__menu {
      max-width: 425px;
      width: 100%;
      background-color: $light-primary;
      height: 100%;
    }

    &__header {
      display: flex;
    }
  }
}

@media (max-width: 500px) {
  .main-layout {
    &__header {
      padding: 12px 16px;
    }

    &__page-wrapper {
      padding: 32px 16px 80px;
    }

    &__title {
      margin-bottom: 32px;
      padding: 0 8px;
    }
  }
}

@media (max-width: 425px) {
  .main-layout {
    &__header {
      gap: 4px;

      img {
        height: 36px;
      }
    }
  }
}

@media (max-width: 360px) {
  .main-layout {
    // &__page-wrapper {
    //   padding: 32px 8px 80px;
    // }

    &__title {
      margin-bottom: 32px;
    }
  }
}
</style>
