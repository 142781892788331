<script>
import MenuRoutes from "@/components/menu/MenuRoutes";
import MainButtonIcon from "@/components/helpers/MainButtonIcon";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    MainButtonIcon,
    MenuRoutes,
  },

  emits: ["close"],

  watch: {
    $route() {
      this.$emit("close");
    },
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

    userName() {
      return `${this.user.data.last_name} ${this.user.data.first_name}`;
    },
  },

  methods: {
    ...mapActions({
      logout: "auth/logout",
    }),

    logoutRequest() {
      this.logout().then(() => {
        window.location = "/login";
      });
    },
  },
};
</script>

<template>
  <div class="menu-component">
    <div class="menu-component__title title">
      <img src="../../assets/logo.svg" alt="" />

      <div class="title__wrapper">
        <p>МАРКЕТПЛЕЙС</p>
      </div>

      <MainButtonIcon
        class="menu-component__button"
        :type="'button'"
        @click="$emit('close')"
      >
        <span class="menu-component__icon icon-close"></span>
      </MainButtonIcon>
    </div>

    <div class="menu-component__profile profile">
      <div class="profile__wrapper">
        <span>Вы вошли как</span>

        <p>{{ userName }}</p>
      </div>

      <MainButtonIcon
        class="profile__button-exit"
        :type="'button'"
        @click="logoutRequest"
      >
        <span class="profile__icon-exit icon-exit"></span>
      </MainButtonIcon>
    </div>

    <menu-routes />
  </div>
</template>

<style lang="scss" scoped>
.menu-component {
  padding: 24px 40px;

  .title {
    margin-bottom: 40px;
    display: flex;
    align-items: center;

    &__wrapper {
      margin-left: 8px;

      p {
        @include text-2();
        color: $dark-sixth;
        font-weight: 400;
      }
    }
  }

  &__button {
    display: none;
    margin-left: auto;
  }

  &__icon {
    display: block;
    width: 32px;
    height: 32px;
    background-color: $dark-primary;
  }

  .profile {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__wrapper {
      span {
        display: block;
        @include text-2;
        color: $dark-fifth;
        margin-bottom: 4px;
      }

      p {
        @include body-1-bold;
        color: $dark-primary;
      }
    }

    &__icon-exit {
      display: block;
      width: 32px;
      height: 32px;
      background-color: $dark-sixth;
    }

    &__button-exit {
      &:hover {
        .profile__icon-exit {
          background-color: $dark-primary;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .menu-component {
    &__button {
      display: block;
    }
  }
}

@media (max-width: 425px) {
  .menu-component {
    padding: 24px 16px;

    .title {
      img {
        height: 36px;
      }

      &__wrapper {
        margin-left: 4px;
      }
    }
  }
}
</style>
