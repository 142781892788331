import { createRouter, createWebHistory } from "vue-router";
import MainLayout from "@/components/layouts/MainLayout";
import RouteLayout from "@/components/layouts/RouteLayout";
import store from "@/store";

const routes = [
  {
    path: "/",
    component: MainLayout,
    children: [
      {
        path: "tsp",
        component: RouteLayout,
        children: [
          {
            path: "",
            name: "TspList",
            component: () => import("@/views/tsp/TspListView"),
            meta: {
              title: "Торгово-сервисные предприятия",
            },
          },
          {
            path: ":id",
            name: "Tsp",
            component: () => import("@/views/tsp/TspView"),
            props: true,
          },
          {
            path: "create",
            name: "TspCreate",
            component: () => import("@/views/tsp/TspCreate"),
          },
          {
            path: ":id/edit",
            name: "TspEdit",
            component: () => import("@/views/tsp/TspEdit"),
            props: true,
          },
          {
            path: ":id/order/:orderId",
            name: "Order",
            component: () => import("@/views/order/OrderView"),
          },
        ],
      },
      {
        path: "staff",
        component: RouteLayout,
        children: [
          {
            path: "",
            name: "Staff",
            component: () => import("@/views/staff/StaffView"),
            meta: {
              title: "Сотрудники",
            },
          },
          {
            path: "create",
            name: "StaffCreate",
            component: () => import("@/views/staff/StaffCreate"),
          },
          {
            path: ":id/edit",
            name: "StaffEdit",
            component: () => import("@/views/staff/StaffEdit"),
            props: true,
          },
        ],
      },
      {
        path: "/logs",
        name: "Logs",
        component: () => import("@/views/logs/LogsView"),
        meta: {
          title: "Лента активности",
        },
      },
    ],
  },
  {
    path: "/login",
    component: () => import("../components/layouts/AuthLayout"),
    children: [
      {
        path: "",
        name: "Authorization",
        component: () => import("../components/auth/AuthForm"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  let user = store.state.auth.user;

  if (user === null) {
    store.dispatch("auth/auth").then(() => {
      store
        .dispatch("auth/loadAccessList")
        .then((response) => {
          let id = response.data.access_list.marketplace[0].context_id;
          store
            .dispatch(`auth/checkUser`, id)
            .then(() => {
              if (to.path === "/login") {
                next("/");
              } else {
                next();
              }
            })

            .catch(() => {
              if (to.path !== "/login") {
                next("/login");
              } else {
                next();
              }
            });
        })
        .catch(() => {
          if (to.path !== "/login") {
            next("/login");
          } else {
            next();
          }
        });
    });
  } else {
    next();
  }
});

export default router;
