import { format } from "date-fns";

export default function groupLogs(logs) {
  const formattedLogs = logs.map((item) => {
    item.date = format(new Date(item.created_at), "dd.MM.yyyy");

    return item;
  });

  const groupedLogs = formattedLogs.reduce((group, item) => {
    const { date } = item;

    group[date] = group[date] ?? [];
    group[date].push(item);

    return group;
  }, {});

  return groupedLogs;
}
