import API from "@/main.js";
import store from "./index";

const state = {
  user: null,
  permissions: null,
};

const getters = {};

const actions = {
  auth() {
    return new Promise((resolve, reject) => {
      API.get("/sanctum/csrf-cookie")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loginUser(_, data) {
    return new Promise((resolve, reject) => {
      API.post("/auth/login", {
        username: data.username,
        password: data.password,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadAccessList() {
    return new Promise((resolve, reject) => {
      API.get("/auth/access-list")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  checkUser({ commit }, id) {
    return new Promise((resolve, reject) => {
      API.get(`marketplaces/v1/${id}/profile/check`)
        .then((response) => {
          commit("SET_USER", response.data);
          commit("SET_PERMISSION", response.data.current_access.permissions);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  logout() {
    return new Promise((resolve, reject) => {
      API.get("auth/logout")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  resetPassword(_, user) {
    return new Promise((resolve, reject) => {
      API.post(`auth/${user}/password/reset`)
        .then((response) => {
          store.dispatch("createNotification", {
            title: "Данные изменены",
            type: "success",
          });

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_USER(state, payload) {
    state.user = payload;
  },

  SET_PERMISSION(state, permissions) {
    state.permissions = permissions;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
