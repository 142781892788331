import API from "@/main.js";
import store from "./index";

const state = {
  tspList: [],
  statusTspList: "",
  tsp: null,
  category: "",

  paymentProvidersTSP: null,
  paymentProviders: null,
  tspLimits: null,

  taxSystems: null,
};

const getters = {};

const actions = {
  loadTspList({ commit }, data) {
    commit("SET_STATUS_TSP_LIST", "LOADING");

    return new Promise((resolve, reject) => {
      API.get(`/marketplaces/v1/${data.id}/partner`, {
        params: {
          page: data.page,
          search: data.search,
        },
      })
        .then((response) => {
          commit("SET_TSP_LIST", response.data.data);
          commit("SET_STATUS_TSP_LIST", "SUCCESS");

          resolve(response);
        })
        .catch((error) => {
          reject(error);
          commit("SET_STATUS_TSP_LIST", "ERROR");

          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },

  storeTsp(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`/marketplaces/v1/${data.id}/partner`, data.tsp, {
        headers: { "Accept-Language": "ru" },
      })
        .then((response) => {
          resolve(response);

          store.dispatch("createNotification", {
            title: "Организация добавлена",
            type: "success",
          });
        })
        .catch((error) => {
          reject(error);

          reject(error);

          let title = "Что-то пошло не так";
          let description = "Перезагрузите страницу или попробуйте позже";

          if (error.response.status === 422) {
            title = error.response.data.message;
            description = "";
          }

          store.dispatch("createNotification", {
            title: title,
            description: description,
            type: "warning",
          });
        });
    });
  },

  loadTsp({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(`/marketplaces/v1/${data.marketplaceId}/partner/${data.tspId}`)
        .then((response) => {
          commit("SET_TSP", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);

          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },

  updateTsp(_, data) {
    return new Promise((resolve, reject) => {
      API.put(
        `/marketplaces/v1/${data.marketplaceId}/partner/${data.tspId}`,
        data.tsp,
        {
          headers: { "Accept-Language": "ru" },
        }
      )
        .then((response) => {
          resolve(response);

          store.dispatch("createNotification", {
            title: "Данные изменены",
            type: "success",
          });
        })
        .catch((error) => {
          reject(error);

          let title = "Что-то пошло не так";
          let description = "Перезагрузите страницу или попробуйте позже";

          if (error.response.status === 422) {
            title = error.response.data.message;
            description = "";
          }

          store.dispatch("createNotification", {
            title: title,
            description: description,
            type: "warning",
          });
        });
    });
  },

  deleteTsp(_, data) {
    return new Promise((resolve, reject) => {
      API.delete(
        `/marketplaces/v1/${data.marketplaceId}/partner/${data.tspId}/soft-delete`
      )
        .then((response) => {
          resolve(response);

          store.dispatch("createNotification", {
            title: "Организация удалена",
            type: "success",
          });
        })
        .catch((error) => {
          reject(error);

          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },

  loadCategoryTsp({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(
        `marketplaces/v1/${data.marketplaceId}/partner/${data.tspId}/category`
      )
        .then((response) => {
          commit("SET_CATEGORY", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);

          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },

  // payment providers

  loadPaymentProviders({ commit }, payload) {
    return new Promise((resolve, reject) => {
      API.get(`marketplaces/v1/${payload.marketplaceId}/partner/payment-data`)
        .then((response) => {
          commit("SET_PAYMENT_PROVIDERS", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);

          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },

  loadTSPPaymentProviders({ commit }, payload) {
    return new Promise((resolve, reject) => {
      API.get(
        `marketplaces/v1/${payload.marketplaceId}/partner/${payload.partnerId}/payment-data`
      )
        .then((response) => {
          commit("SET_TSP_PAYMENT_PROVIDERS", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);

          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },

  storePaymentProvider(_, payload) {
    return new Promise((resolve, reject) => {
      API.post(
        `marketplaces/v1/${payload.marketplaceId}/partner/${payload.partnerId}/payment-data`,
        {
          payment_provider_id: payload.payment_provider_id,
          data: payload.data,
        }
      )
        .then((response) => {
          resolve(response);

          store.dispatch("createNotification", {
            title: "Провайдер привязан",
            type: "success",
          });
        })
        .catch((error) => {
          if (error.response.status === 400) {
            store.dispatch("createNotification", {
              title: "Провайдер не привязан",
              description: "Данные введены неверно",
              type: "warning",
            });
          } else {
            store.dispatch("createNotification", {
              title: "Провайдер не привязан",
              description: "Что-то пошло не так",
              type: "warning",
            });
          }

          reject(error);
        });
    });
  },

  updatePaymentProvider(_, payload) {
    return new Promise((resolve, reject) => {
      API.put(
        `marketplaces/v1/${payload.marketplaceId}/partner/${payload.partnerId}/payment-data/${payload.providerId}`,
        {
          data: payload.data,
        }
      )
        .then((response) => {
          resolve(response);

          store.dispatch("createNotification", {
            title: "Данные изменены",
            type: "success",
          });
        })
        .catch((error) => {
          reject(error);

          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },

  destroyPaymentProvider(_, payload) {
    return new Promise((resolve, reject) => {
      API.delete(
        `marketplaces/v1/${payload.marketplaceId}/partner/${payload.partnerId}/payment-data/${payload.providerId}`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);

          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },

  activatePaymentProvider(_, payload) {
    return new Promise((resolve, reject) => {
      API.put(
        `marketplaces/v1/${payload.marketplaceId}/partner/${payload.partnerId}/cashier/toggle`,
        payload.data
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadPartnerLimits({ commit }, payload) {
    return new Promise((resolve, reject) => {
      API.get(
        `marketplaces/v1/${payload.marketplaceId}/partner/${payload.partnerId}/limit`
      )
        .then((response) => {
          commit("SET_PARTNER_LIMITS", response.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  storePartnerLimits(_, payload) {
    return new Promise((resolve, reject) => {
      API.post(
        `marketplaces/v1/${payload.marketplaceId}/partner/${payload.partnerId}/limit`,
        payload.params
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deletePartnerLimits(_, payload) {
    return new Promise((resolve, reject) => {
      API.delete(
        `marketplaces/v1/${payload.marketplaceId}/partner/${payload.partnerId}/limit`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadTaxSystems({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(`marketplaces/v1/${data.marketplaceId}/partner/tax-systems`)
        .then((response) => {
          commit("SET_TAX_SYSTEMS", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_TSP_LIST(state, tspList) {
    state.tspList = tspList;
  },

  SET_STATUS_TSP_LIST(state, payload) {
    state.statusTspList = payload;
  },

  SET_TSP(state, tsp) {
    state.tsp = tsp;
  },

  SET_CATEGORY(state, category) {
    state.category = category;
  },

  SET_TSP_PAYMENT_PROVIDERS(state, payload) {
    state.paymentProvidersTSP = payload;
  },

  SET_PAYMENT_PROVIDERS(state, payload) {
    state.paymentProviders = payload;
  },

  SET_PARTNER_LIMITS(state, payload) {
    state.tspLimits = payload;
  },

  SET_TAX_SYSTEMS(state, payload) {
    state.taxSystems = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
