import API from "@/main.js";
import store from "./index";

const state = {
  orders: [],
  order: null,
  proceeds: "-",
};

const getters = {};

const actions = {
  loadOrders({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(
        `/marketplaces/v1/${data.marketplaceId}/partner/${data.tspId}/orders`,
        {
          params: {
            search: data.search,
            page: data.page,
          },
        }
      )
        .then((response) => {
          commit("SET_ORDERS", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);

          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },

  loadOrder({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(
        `/marketplaces/v1/${data.marketplaceId}/partner/${data.tspId}/orders/${data.orderId}`
      )
        .then((response) => {
          commit("SET_ORDER", response.data.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);

          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },

  loadProceeds({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(
        `/marketplaces/v1/${data.marketplaceId}/partner/${data.tspId}/orders/statistics`
      )
        .then((response) => {
          commit("SET_PROCEEDS", response.data.total_sum);

          resolve(response);
        })
        .catch((error) => {
          reject(error);

          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },

  syncOrders(_, data) {
    return new Promise((resolve, reject) => {
      API.post(
        `/marketplaces/v1/${data.marketplaceId}/partner/${data.tspId}/orders/sync`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);

          store.dispatch("createNotification", {
            title: "Что-то пошло не так",
            description: "Перезагрузите страницу или попробуйте позже",
            type: "warning",
          });
        });
    });
  },
};

const mutations = {
  SET_ORDERS(state, orders) {
    state.orders = orders;
  },

  SET_ORDER(state, order) {
    state.order = order;
  },

  SET_PROCEEDS(state, proceeds) {
    state.proceeds = proceeds;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
