export const MENU_ROUTES = [
  {
    id: 1,
    path: "/tsp",
    name: "ТСП",
    icon: "tsp",
    permissions: [
      "marketplace",
      "marketplace.partners",
      "marketplace.partners.view",
    ],
  },
  {
    id: 2,
    path: "/staff",
    name: "Сотрудники",
    icon: "people",
    permissions: [
      "marketplace",
      "marketplace.access",
      "marketplace.access.view",
    ],
  },
  {
    id: 3,
    path: "/logs",
    name: "Лента активности",
    icon: "list",
    permissions: [
      "marketplace",
      "marketplace.activity",
      "marketplace.activity.view",
    ],
  },
];
